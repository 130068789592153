import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import {
  QueryClientProvider,
  Hydrate,
  QueryClient,
} from "@tanstack/react-query";
import { NextIntlProvider } from "next-intl";
import FontLoader from "shared/components/font-loader";
import MainLayout from "shared/components/layout";
import theme from "styles/theme/theme";
import "../styles/globals.css";
import "../styles/helper-spacing.css";
import React, { useEffect } from "react";
import { SnackbarProvider } from "notistack";
import { GetStaticPropsContext } from "next";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import en from "lang/en.json";
import es from "lang/es.json";
import { useRouter } from "next/router";
import { Provider } from "react-redux";
import { store } from "store";
import Head from "next/head";
import NewsContext from "shared/context/news.context";
import BannersModal from "@components/news/modal-news";
import { ModalProvider } from "shared/context/ModalAlert.context";

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      messages: locale === "es" ? es : en,
    },
  };
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function MyApp(props: any) {
  const { Component, pageProps } = props;
  const router = useRouter();

  useEffect(() => storePathValues, [router.asPath]);

  function storePathValues() {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;

    if (storage.getItem("currentPath") != globalThis.location.pathname) {
      // Set the previous path as the value of the current path.
      const prevPath: any = storage.getItem("currentPath");
      storage.setItem("prevPath", prevPath);
    }
    // Set the current path value by looking at the browser's location object.
    storage.setItem("currentPath", globalThis.location.pathname);
  }

  const Layout = Component.Layout || React.Fragment;

  function loaderIdiom() {
    // obtener el idioma del localstorage
    const loaderIdiom = localStorage.getItem("idiom");
    if (loaderIdiom) {
      //verificar si el idioma es diferente al idioma actual
      if (loaderIdiom !== router.locale) {
        //si es diferente se cambia el idioma
        router.push(router.asPath, router.asPath, {
          locale: loaderIdiom.toLowerCase(),
        });
      }
    }
  }

  useEffect(() => {
    loaderIdiom();
  }, []);
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        
        {/* <script src="https://unpkg.com/react-scan/dist/auto.global.js"></script> */}
      </Head>
      <FontLoader></FontLoader>
      <NextIntlProvider messages={pageProps.messages}>
        <ThemeProvider theme={theme}>
          {/* <BannersModal/> */}
          <SnackbarProvider maxSnack={3}>
            <CssBaseline />
            <ModalProvider>
              <QueryClientProvider client={queryClient}>
                <Hydrate state={pageProps.dehydratedState}>
                  <Provider store={store}>
                    <NewsContext>
                      <MainLayout>
                        <Layout>
                          <Component {...pageProps} />
                        </Layout>
                      </MainLayout>
                    </NewsContext>
                  </Provider>
                </Hydrate>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </ModalProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </NextIntlProvider>
    </>
  );
}

export default MyApp;
